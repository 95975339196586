
.text-gradientTest {
    background: linear-gradient(0deg, #0043ff -7.64%, #009fff 36.11%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
  }

  .carousel-container {
    display: flex;
    overflow: hidden;
    transition: transform 0.5s ease-in-out;
    padding: 30px;
  }

  .slide {
    min-width: 40%;
    padding: 20px;
    margin-right: 10px;
    background-color: #282932;
    /* border-top: 10px solid #0046FF; */
    /* border-right: 10px solid #0046FF; */
    /* border-radius: 17px; */
    transition: transform 0.5s ease-in-out;
    cursor: pointer;
    height: 100%;
    /* text-align: justify; */
     padding-bottom: 30px;
     background: linear-gradient(#282932, #282932) padding-box,
     linear-gradient(to top, #0046FF, #009DFF) border-box;
   border-radius: 17px;
   border-top: 10px solid transparent;
   border-right: 10px solid transparent;
  }

  .slide-next {
    transform: translateX(-50%);
  }
  
  /* .slide-previous {
    display: none;
  } */

  .zoomable {
    transition: transform 0.3s ease-in-out;
  }
  
  .zoomable:hover {
    transform: scale(1.2); 
  }
  

  @media screen and (max-width:768px){
    .slide{
      min-width: 100%;
      /* height: 800px; */
    }
  }


  @media screen and (min-width:1024px){
    .slide{
      width: 60%;
      max-width: 40px;
    }
  }





  
