.videoComp {
  position: relative;
  /* bottom: 40px; */
  transform: skewy(-5deg);
}

.text-gradientVid {
  background: linear-gradient(180deg, #0043ff -7.64%, #009fff 36.11%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.demo {
  background: linear-gradient(95deg, #094cad 0%, #00a2ff 100%);
}

/* .videoContainer {
  background: linear-gradient(
    90deg,
    rgba(17, 34, 80, 1) 35%,
    rgba(10, 0, 34, 1) 100%
  );
} */

.text-gradientVidHead {
  transform: skewy(5deg);
  background: linear-gradient(180deg, #0043ff -7.64%, #009fff 36.11%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.videoContent {
  transform: skewy(5deg);
}

.videoBackGradient {
  background: linear-gradient(0deg, #4cb8ff 1.52%, #eef5ff 136.27%);
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.25);
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 650px;
  margin: auto;
  /* background: linear-gradient(0deg, #4cb8ff 1.52%, #eef5ff 136.27%); */
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.25);
}

video {
  width: 100%;
  height: auto;
  display: block;
}

.video-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.videoBox {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to top, #4cb8ff, #eef5ff) border-box;
  border-radius: 20px;
  border: 10px solid transparent;
}

@media screen and (max-width: 768px) {
  .videoBox{
    border: 5px solid transparent
  }
}