.blogDetailsBackground{
    background: linear-gradient(180deg, #000F3F 1.62%, #000F3F 28.87%, #035891 100%);
}

.blogHeaderBack{
    background: linear-gradient(120deg, rgba(10,0,37,1) 35%, rgba(25,78,132,1) 90%);
}

.homeButton{
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

.homeButton:hover{
    color: #0046FF;
}