.backContTwo {
  background-color: #282932;
  /* filter: drop-shadow(0px 20px 31px rgba(0, 0, 0, 0.25)); */
  /* position: relative; */
  /* overflow: hidden;
  bottom: 40px;
  z-index: 1; */
  /* bottom: 41px;
  transform: rotate(-2deg);
  height: 640px; */
}

.backContTwo {
  transform: skewy(-5deg);
}

.tiltBGHomepage {
  background: linear-gradient(
    184deg,
    rgba(42, 124, 195, 1) 35%,
    rgba(19, 43, 92, 1) 83%
  );
}

.overlook {
  transform: skewy(5deg);
}

.checkImage {
  transform: skewy(5deg);
}

.mobileFactory {
  transform: skewy(5deg);
  padding-left: 15px;
}