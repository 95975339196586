@font-face {
    font-family: sansation;
    src: url('../../Fonts/sansation/Sansation_Regular.ttf');
}

.blogback{
    background-image: url('../Images/backContactus.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.blogHeading{
    font-family: 'sansation', sans-serif;
}

.blogContentBackground{
    /* background: rgba(0, 0, 0, 0.36); */
    background: 
    linear-gradient(#000210, #000210) padding-box,
    linear-gradient(to bottom, #0943CB, #20DEEB, #009FFF) border-box;
  border-radius: 24px;
  border: 7px solid transparent;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
}

.blogReadButton{
    background: linear-gradient(90deg, #0945CB -6.95%, #1F84EC 102.37%);
}

