.heroAbout {
  background: linear-gradient( #01346A 40%, #000F3F 100%);
}

.aboutContent {
  background: linear-gradient(180deg, #000f3f 25.54%, #037ec4 124.74%);
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.25);
}

.vision {
  background-color: #282932;
  box-shadow: 0px -20px 31px rgba(0, 0, 0, 0.25);
}

.automate {
  background: linear-gradient(180deg, #0043FF 0%, #009FFF 92.81%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.CEO {
  background: linear-gradient(180deg, #0046ff 0%, #009fff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.tiltSpring{
  transform: skew(0deg, 351deg);
  position: absolute;
  top: -40px;
  right: 0;
}

@media screen and (max-width: 639px) {
  .tiltSpring{
    transform: skew(0deg, 341deg);
    top: 10px;
  }
}

@media screen and (max-width: 639px) {
  .cardFounder:hover {
    border-top: 5px solid #0046ff;
    border-right: 5px solid #0046ff;
    border-radius: 17px;
    transition: border 0.3s ease;
  }
}