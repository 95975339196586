.IIOT{
    background: #282932;
    transform: skewy(-5deg); 
    box-shadow: 0px -20px 31px rgba(0, 0, 0, 0.25);
  }

  .IIOTContent{
    transform: skewy(5deg);
  }

  .iiotDetails{
    transform: skewy(5deg);
  }

  .computerVisionMobile{
    transform: skewy(5deg);
    margin-top: 20px;
  }