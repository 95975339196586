.MuiFormControl-root{
    margin-top: 20px !important;
}



.MuiInput-underline{
    border-bottom: 1px solid white !important;
}

.MuiFormLabel-root{
    font-size: 14px !important;
    color: #c8c8c8 !important;
}

.MuiInput-root{
    font-family: 'Poppins', sans-serif !important;
}

.MuiTypography-root{
    color: #B7B7B7 !important;
}

.MuiCheckbox-root{
    color: white !important;
}

.MuiInputBase-input {
    color: white !important;
}

.Mui-error{
    font-family: "Poppins", sans-serif !important;
}

@media (max-width:639px){
.MuiFormControl-root{
    margin-top: 10px !important;
}
.MuiFormLabel-root{
    font-size: 10px !important;
}
.MuiInputBase-input{
    font-size: 12px !important;
    font-weight: 200 !important;
}

.MuiTypography-root{
    font-size: 12px !important;
}
}