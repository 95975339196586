.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    backdrop-filter: blur(10px); 
    z-index: 1000;
    padding: 10px;
}

.rs-dropdown-menu{
    border: 1px solid white;
    border-radius: 4px;
    padding: 15px;
    overflow: hidden; 
    width: 130px;
    background-color: #00153f;
}

.rs-dropdown-menu::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}