.pdf-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .pdf-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

 .react-pdf__Document {
    width: 100% !important;
    height: 700px !important;
  }
  
  .react-pdf__Page {
    width: 100%;
    /* height: 650px; */
    padding-right: 10px;
    padding-left: 10px;
  }
  
.react-pdf__Page__canvas {
    height: 100% !important;
    width: 100% !important;
  }
  
  .close-btn {
    position: fixed;
    top: 10px;
    right: 15px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1001;
  }

  .video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(41, 41, 41, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  
    @media screen and (min-width: 1537px){
      .react-pdf__Page{
        height: 900px;
      }
    }

    @media screen and (max-width: 640px){
      .react-pdf__Page{
        height: 300px;
      }
    }

   


  